import "./Intro.scss";
import { Player, Video } from "liqvid";

import { DisablePauseClickCanvas } from "../../liqvid/components/Interactivity";
import { RememberVolume } from "../../liqvid/components/Recipes";

import {
	script_intro,
	playback_intro,
} from "./markers";
import { customControls } from "../CustomControls/CustomControls";
import {
	Loading,
} from "../Sections/Sections";

import { useRef } from "react";

export function Intro({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_intro;
	const playback = playback_intro;
	const videoRef = useRef(null);

	script.on("markerupdate", () => {
		var marker_name = script.markerName;
		if (marker_name === "video/redirection") {
			onSceneChange("scene1");
		}
	});

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				className="video_wrp"
			>
				<Video
					className="vid_fluid"
					ref={videoRef}
					start={0}
				>
					<source
						src="/videos/intro.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/intro.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/intro.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			{true && <Loading autoPlay={isAutoplay} videoRef={videoRef} playback={playback} />}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}