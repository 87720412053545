import "./Outro.scss";
import { Player, Video } from "liqvid";

import { DisablePauseClickCanvas } from "../../liqvid/components/Interactivity";
import { RememberVolume } from "../../liqvid/components/Recipes";
import { Typing } from "../../liqvid/components/Animations";

import { bounceIn, fadeIn } from "../../liqvid/functions/Animations";

import { script_outro, playback_outro } from "./markers";
import { customControls } from "../CustomControls/CustomControls";
import { Loading } from "../Sections/Sections";

import { useRef } from "react";

export function Outro({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_outro;
	const playback = playback_outro;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section data-during="video/" className="video_wrp">
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/outro.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/outro.webm"
						type="video/webm; codecs=vp9"
					/>
					<source src="/videos/outro.mp4" type="video/mp4" />
				</Video>
			</section>
			<section data-during="video/offer" className="section_offer">
				<div className="logo_wrp">
					<img
						src="/img/logo-fff-900.webp"
						className="logo"
						alt="logo"
						width="200"
						height="65"
						ref={fadeIn(playback, script.parseStart("video/offer"))}
					/>
				</div>
				<div className="content">
					<h3 className="text">
						<Typing speed={50} start={script.parseStart("video/offer")}>
							Tentez de gagner une Smartbox « Rendez-vous gourmand » en répondant à la question sur le lien suivant
						</Typing>
					</h3>
					<span
						className="btn_wrp"
						ref={bounceIn(
							playback,
							script.parseStart("video/offer") + 6000
						)}
					>
						<a
							href="https://forms.gle/6hfcwcfR6VYSerk77"
							target="_blank"
							className="app_btn"
							rel="noreferrer"
						>
							Cliquez ici
						</a>
					</span>
				</div>
			</section>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}
