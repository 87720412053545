import { useEffect } from "react";
import { usePlayer } from "liqvid";

/**
 * Disable pause-on-click canvas.  
 */
export function DisablePauseClickCanvas() {
    // since this calls usePlayer(), cannot put directly in <MyVideo>
    const player = usePlayer();
    useEffect(() => {
        player.hub.on("canvasClick", () => false);
    }, [player]);
    return null;
}