import "./Sections.scss";
import React from "react";

import { combineRefs } from "@liqvid/utils/react";

import {
	flipInY,
	bounceIn,
	bounceOut,
} from "../../liqvid/functions/Animations";
import { Typing } from "../../liqvid/components/Animations";

//import Timing from "../Timing/Timing";

import { useState, useEffect } from "react";
import { usePlayer, Utils } from "liqvid";

/**
 * Disable pause-on-click canvas.
 */
window.lastTimeWaitingVideo = null;

export function Loading({
	autoPlay = false,
	videoRef = null,
	//playback = null,
}) {
	const [isHide, setIsHide] = useState(false);
	const { playback } = usePlayer();

	useEffect(() => {
		const handleWaitingVideo = (vidRef) => {
			return (event) => {
				//console.log("video-waiting");
				playback.waiting = true;
				playback.waitCurrentTime = playback.currentTime;
				setIsHide(false);
			};
		};

		const handlePlayingVideo = (vidRef) => {
			return (event) => {
				//console.log("video-playing");
				playback.waiting = false;
				playback.waitCurrentTime = null;
				setIsHide(true);
			};
		};

		const handleCanplayVideo = (vidRef) => {
			return (event) => {
				//console.log("video-canplay");
				setIsHide(true);
				if (playback.waiting) {
					playback.play();
					playVideo(vidRef);
				}
				if (autoPlay && playback.currentTime === 0) {
					changePropVideo(vidRef, "autoplay", true);
					playback.play();
					playVideo(vidRef);
				}
			};
		};

		const handleCanplayThroughVideo = (vidRef) => {
			return (event) => {
				//console.log("video-canplay-through");
				setIsHide(true);
				if (playback.waiting) {
					playback.play();
				}
				if (autoPlay && playback.currentTime === 0) {
					changePropVideo(vidRef, "autoplay", true);
					playback.play();
					playVideo(vidRef);
				}
			};
		};

		const handleOnplayVideo = (event) => {
			//console.log("video-onplay");
			playback.waiting = false;
			playback.waitCurrentTime = null;
			setIsHide(true);
		};

		const changePropVideo = (vidRef, prop, value) => {
			if (vidRef && vidRef.current && vidRef.current.domElement) {
				vidRef.current.domElement[prop] = value;
			}
		};

		const playVideo = (vidRef) => {
			if (vidRef && vidRef.current && vidRef.current.domElement) {
				vidRef.current.domElement
					.play()
					.then(() => {})
					.catch((error, x) => {
						//console.log("error playing", error, x);
						if (vidRef && vidRef.current && vidRef.current.domElement) {
							vidRef.current.domElement.muted = true;
							vidRef.current.domElement.play();
						}
					});
			}
		};

		const handleClicDocument = (vidRef) => {
			return (event) => {
				changePropVideo(vidRef, "muted", false);
			};
		};

		const handlePlaybackTimeupdate = (vidRef) => {
			return (xCurrentTime) => {
				//console.log("playback-timeupdate")
				if (
					playback.waiting &&
					playback.waitCurrentTime !== null &&
					playback.paused === false
				) {
					setIsHide(false);
					playback.seek(playback.waitCurrentTime);
				}

				if (
					playback.waiting ||
					!vidRef ||
					!vidRef.current ||
					!vidRef.current.domElement
				) {
					return;
				}

				var buffered = vidRef.current.domElement.buffered;
				var currentTime = xCurrentTime / 1000;
				var duration = vidRef.current.domElement.duration;

				for (var i = 0; i < buffered.length; i++) {
					if (
						buffered.start(i) <= currentTime &&
						buffered.end(i) <= currentTime &&
						buffered.end(i) < duration
					) {
						// Unuffisamment de données tamponnées pour lire la vidéo sans interruption
						//console.log("last buffer", buffered.end(i));
						playback.waiting = true;
						playback.waitCurrentTime = buffered.end(i) * 1000;
						setIsHide(false);
						break;
					}
				}
			};
		};

		const handlePlaybackSeek = (vidRef) => {
			return (event) => {
				//console.log("playback-seek", playback.currentTime)
				playback.waitCurrentTime = playback.currentTime;
				if (!vidRef || !vidRef.current || !vidRef.current.domElement) {
					return;
				}

				var buffered = vidRef.current.domElement.buffered;
				var currentTime = playback.waitCurrentTime / 1000;
				var duration = vidRef.current.domElement.duration;

				for (var i = 0; i < buffered.length; i++) {
					if (
						buffered.start(i) <= currentTime &&
						(buffered.end(i) >= currentTime + 5 ||
							buffered.end(i) >= duration)
					) {
						// Suffisamment de données tamponnées pour lire la vidéo sans interruption
						//console.log("last buffer", buffered.end(i));
						if (playback.waiting) {
							playback.waiting = false;
							playback.waitCurrentTime = null;
							playback.play();
							setIsHide(true);
						}
						break;
					}
				}
			};
		};

		const handlePlaybackSeeked = () => {
			//console.log("playback-seeked")
		};

		const handlePlaybackPause = () => {
			//console.log("playback-pause")
			setIsHide(true);
			playback.waiting = false;
			playback.waitCurrentTime = null;
		};

		const handlePlaybackPlay = (vidRef) => {
			return (event) => {
				//console.log("playback-play")
				playVideo(vidRef);
				setIsHide(true);
			};
		};

		const addEventsVideoOrPlayback = (vidRef) => {
			if (vidRef && vidRef.current && vidRef.current.domElement) {
				// save video to playback
				playback.currentVideo = vidRef.current.domElement

				vidRef.current.domElement.addEventListener(
					"waiting",
					handleWaitingVideo(vidRef)
				);
				vidRef.current.domElement.addEventListener(
					"playing",
					handlePlayingVideo(vidRef)
				);
				vidRef.current.domElement.addEventListener(
					"canplay",
					handleCanplayVideo(vidRef)
				);
				vidRef.current.domElement.addEventListener(
					"canplaythrough",
					handleCanplayThroughVideo(vidRef)
				);
				vidRef.current.domElement.addEventListener(
					"play",
					handleOnplayVideo
				);
				document.addEventListener("click", handleClicDocument(vidRef));
			}
			if (playback) {
				playback.on("timeupdate", handlePlaybackTimeupdate(vidRef));
				playback.on("pause", handlePlaybackPause);
				playback.on("seek", handlePlaybackSeek(vidRef));
				playback.on("seeked", handlePlaybackSeeked);
				playback.on("play", handlePlaybackPlay(vidRef));
			}
		};

		const removeEventsVideoOrPlayback = (vidRef) => {
			if (vidRef && vidRef.current && vidRef.current.domElement) {
				vidRef.current.domElement.removeEventListener(
					"waiting",
					handleWaitingVideo
				);
				vidRef.current.domElement.removeEventListener(
					"playing",
					handlePlayingVideo
				);
				vidRef.current.domElement.removeEventListener(
					"canplay",
					handleCanplayVideo
				);
				vidRef.current.domElement.removeEventListener(
					"canplaythrough",
					handleCanplayThroughVideo
				);
				vidRef.current.domElement.removeEventListener(
					"play",
					handleOnplayVideo
				);
				document.removeEventListener("click", handleClicDocument);
			}
			if (playback) {
				playback.off("timeupdate", handlePlaybackTimeupdate);
				playback.off("pause", handlePlaybackPause);
				playback.off("seek", handlePlaybackSeek);
				playback.off("seeked", handlePlaybackSeeked);
				playback.off("play", handlePlaybackPlay);
			}
		};

		removeEventsVideoOrPlayback(videoRef);
		addEventsVideoOrPlayback(videoRef);

		return () => {
			removeEventsVideoOrPlayback(videoRef);
		};
	}, [videoRef, playback, autoPlay]);

	var classname = "";

	if (isHide || playback.paused) {
		classname = "hide";
	}

	return (
		<div className={`spinner_wrp ${classname}`}>
			<div className="spinner_border"></div>
		</div>
	);
}

export function SectionChoices({ script, playback, params = {} }) {
	const anim_height = (
		playback,
		delay = 0,
		duration = 10,
		easing = "linear",
		fill = "forwards"
	) => {
		return playback.newAnimation(
			[
				{
					offset: 0,
					height: "0",
				},
				{
					offset: 1,
					height: "auto",
				},
			],
			{
				delay,
				duration,
				easing,
				fill,
			}
		);
	};

	const start_marker_video_choices = script.parseStart("video/choices");
	//const end_marker_video_choices = script.parseEnd("video/choices");

	const start_ref_choices_wrp = start_marker_video_choices + 3000;
	//const start_ref_choices_last_item = start_ref_choices_wrp + 1010 * params["choice_items"].length;

	/*const start_timing = start_ref_choices_last_item + 1000;
    const duration_timing = end_marker_video_choices - start_timing - 1000;
    const end_timing = end_marker_video_choices - 1000;

    const ref_timing_wrp; = combineRefs(
    	bounceIn(playback, start_timing),
    	bounceOut(playback, end_timing)
    );*/
	const ref_choices_wrp = combineRefs(
		anim_height(playback, start_ref_choices_wrp)
	);

	const ChoiceItems = params["choice_items"].map((item, i) => {
		const start_ref_choices_itemN = start_ref_choices_wrp + 1010 * (i + 1);

		// event handler
		const onClick$1 = Utils.react.onClick;
	    const events = (0, React.useMemo)(
	        () =>
	            onClick$1(item.onClick),
	        []
	    );

		return (
			<li
				key={i}
				className="choice_item"
				ref={combineRefs(flipInY(playback, start_ref_choices_itemN))}
			>
				<div className="choice_content" {...events} >
					{item.text}
				</div>
			</li>
		);
	});

	return (
		<section data-from-first="video/choices" className="section_choices">
			{/*<div className="timing_wrp" ref={ref_timing_wrp}>
				<Timing start={start_timing} duration={duration_timing} />
			</div>*/}
			<div className="content">
				<h2 className="ttl">
					<Typing speed={50} start={start_marker_video_choices}>
						{params.title}
					</Typing>
				</h2>
				<div className="choices_wrp" ref={ref_choices_wrp}>
					<ul className="choice_list">{ChoiceItems}</ul>
				</div>
			</div>
		</section>
	);
}

export function SectionTitle({ script, playback, text = "" }) {
	return (
		<section data-during="video/title" className="section_title">
			<div className="content">
				<h2 className="title">
					<Typing speed={50} start={script.parseStart("video/title")}>
						{text}
					</Typing>
				</h2>
			</div>
		</section>
	);
}

export function SectionResult({
	script,
	playback,
	isGoodAnswer = false,
	text = null,
}) {
	const start = script.parseStart("video/result");
	const end = script.parseEnd("video/result");

	const ref_ico = combineRefs(
		bounceIn(playback, start),
		bounceOut(playback, end)
	);

	const ref_txt = combineRefs(
		bounceIn(playback, start + 500),
		bounceOut(playback, end)
	);

	if (isGoodAnswer) {
		return (
			<section data-during="video/result" className="section_result good">
				<div className="content">
					<div className="blk">
						<div className="ico_wrp" ref={ref_ico}>
							<img
								src="/img/check-vert.webp"
								className="ico"
								alt=""
							/>
						</div>
						<h2 className="text" ref={ref_txt}>
							{text}
						</h2>
					</div>
				</div>
			</section>
		);
	} else {
		return (
			<section data-during="video/result" className="section_result fail">
				<div className="content">
					<div className="blk">
						<div className="ico_wrp" ref={ref_ico}>
							<img
								src="/img/croix-rouge.webp"
								className="ico"
								alt=""
							/>
						</div>
						<h2 className="text" ref={ref_txt}>
							{text}
						</h2>
					</div>
				</div>
			</section>
		);
	}
}

export function animateBlur(script, playback) {
	const anim_blur = (
		playback,
		delay = 0,
		duration = 500,
		easing = "linear",
		fill = "both"
	) => {
		return playback.newAnimation(
			[
				{
					offset: 0,
					filter: "blur(0)",
				},
				{
					offset: 1,
					filter: "blur(7px)",
				},
			],
			{
				delay,
				duration,
				easing,
				fill,
			}
		);
	};

	const start_marker_video_choices = script.parseStart("video/choices");
	const end_marker_video_choices = script.parseEnd("video/choices");
	const duration = end_marker_video_choices - start_marker_video_choices;

	return anim_blur(playback, start_marker_video_choices, duration);
}
