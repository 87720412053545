import { useRef, createElement } from "react";
import { useTime } from "liqvid";
import { clamp } from "@liqvid/utils/misc";

/**
 * Typing animation
 * usage1 : <Typing speed={50} tagName="h1">Hello World!</Typing>
 * usage2 : start l'animation dont 15000ms 
 * => <Typing start={15000} speed={50} tagName="h1">Hello World!</Typing>
 */
export function Typing({
    children,
    start = 0,
    speed = 50,
    tagName = "span"
}) {
    const ref = useRef();
    useTime(
        (count) => {
            if (ref && ref.current && children) {
                ref.current.textContent = children.slice(0, count);
            }
        },
        t => 
            clamp(0, Math.floor((t - start) / speed), children.length)
        ,
        []
    );
    return createElement(tagName, { ref });
}