/**
 * Author : <Arian Taylor> <arianrabeson@gmail.com>
 * refs : https://animate.style/
 */


// ==================================
// ============= Bounce =============
// ==================================
/**
 * animate bounceIn
 * usage1 : <element ref={bounceIn(playback)}></element>
 * usage2 : <element ref={bounceIn(playback, 3000)}></element>
 * usage3 : <element ref={bounceIn(playback, 3000, 2000)}></element>
 */
export const bounceIn = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale3d(0.3, 0.3, 0.3)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.2,
                transform: "scale3d(1.1, 1.1, 1.1)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.4,
                transform: "scale3d(0.9, 0.9, 0.9)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "scale3d(1.03, 1.03, 1.03)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.8,
                transform: "scale3d(0.97, 0.97, 0.97)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "scale3d(1, 1, 1)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceInDown
 * usage1 : <element ref={bounceInDown(playback)}></element>
 * usage2 : <element ref={bounceInDown(playback, 3000)}></element>
 * usage3 : <element ref={bounceInDown(playback, 3000, 2000)}></element>
 */
export const bounceInDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(0, -3000px, 0) scaleY(3)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "translate3d(0, 25px, 0) scaleY(0.9)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.75,
                transform: "translate3d(0, -10px, 0) scaleY(0.95)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.9,
                transform: "translate3d(0, 5px, 0) scaleY(0.985)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceInLeft
 * usage1 : <element ref={bounceInLeft(playback)}></element>
 * usage2 : <element ref={bounceInLeft(playback, 3000)}></element>
 * usage3 : <element ref={bounceInLeft(playback, 3000, 2000)}></element>
 */
export const bounceInLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(-3000px, 0, 0) scaleX(3)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "translate3d(25px, 0, 0) scaleX(1)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.75,
                transform: "translate3d(-10px, 0, 0) scaleX(0.98)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.9,
                transform: "translate3d(5px, 0, 0) scaleX(0.995)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceInRight
 * usage1 : <element ref={bounceInRight(playback)}></element>
 * usage2 : <element ref={bounceInRight(playback, 3000)}></element>
 * usage3 : <element ref={bounceInRight(playback, 3000, 2000)}></element>
 */
export const bounceInRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(3000px, 0, 0) scaleX(3)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "translate3d(-25px, 0, 0) scaleX(1)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.75,
                transform: "translate3d(10px, 0, 0) scaleX(0.98)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.9,
                transform: "translate3d(-5px, 0, 0) scaleX(0.995)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceInUp
 * usage1 : <element ref={bounceInUp(playback)}></element>
 * usage2 : <element ref={bounceInUp(playback, 3000)}></element>
 * usage3 : <element ref={bounceInUp(playback, 3000, 2000)}></element>
 */
export const bounceInUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(0, 3000px, 0) scaleY(5)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "translate3d(0, -20px, 0) scaleY(0.9)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.75,
                transform: "translate3d(0, 10px, 0) scaleY(0.95)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 0.9,
                transform: "translate3d(0, -5px, 0) scaleY(0.985)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceOut
 * usage1 : <element ref={bounceOut(playback)}></element>
 * usage2 : <element ref={bounceOut(playback, 3000)}></element>
 * usage3 : <element ref={bounceOut(playback, 3000, 2000)}></element>
 */
export const bounceOut = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0.2,
                transform: "scale3d(0.9, 0.9, 0.9)",
            },
            {
                offset: 0.5,
                opacity: 1,
                transform: "scale3d(1.1, 1.1, 1.1)",
            },
            {
                offset: 0.55,
                opacity: 1,
                transform: "scale3d(1.1, 1.1, 1.1)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "scale3d(0.3, 0.3, 0.3)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceOutDown
 * usage1 : <element ref={bounceOutDown(playback)}></element>
 * usage2 : <element ref={bounceOutDown(playback, 3000)}></element>
 * usage3 : <element ref={bounceOutDown(playback, 3000, 2000)}></element>
 */
export const bounceOutDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0.2,
                transform: "translate3d(0, 10px, 0) scaleY(0.985)",
            },
            {
                offset: 0.4,
                opacity: 1,
                transform: "translate3d(0, -20px, 0) scaleY(0.9)",
            },
            {
                offset: 0.45,
                opacity: 1,
                transform: "translate3d(0, -20px, 0) scaleY(0.9)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "translate3d(0, 2000px, 0) scaleY(3)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceOutLeft
 * usage1 : <element ref={bounceOutLeft(playback)}></element>
 * usage2 : <element ref={bounceOutLeft(playback, 3000)}></element>
 * usage3 : <element ref={bounceOutLeft(playback, 3000, 2000)}></element>
 */
export const bounceOutLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0.2,
                opacity: 1,
                transform: "translate3d(20px, 0, 0) scaleX(0.9)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "translate3d(-2000px, 0, 0) scaleX(2)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceOutRight
 * usage1 : <element ref={bounceOutRight(playback)}></element>
 * usage2 : <element ref={bounceOutRight(playback, 3000)}></element>
 * usage3 : <element ref={bounceOutRight(playback, 3000, 2000)}></element>
 */
export const bounceOutRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0.2,
                opacity: 1,
                transform: "translate3d(-20px, 0, 0) scaleX(0.9)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "translate3d(2000px, 0, 0) scaleX(2)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate bounceOutUp
 * usage1 : <element ref={bounceOutUp(playback)}></element>
 * usage2 : <element ref={bounceOutUp(playback, 3000)}></element>
 * usage3 : <element ref={bounceOutUp(playback, 3000, 2000)}></element>
 */
export const bounceOutUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0.2,
                transform: "translate3d(0, -10px, 0) scaleY(0.985)",
            },
            {
                offset: 0.4,
                opacity: 1,
                transform: "translate3d(0, 20px, 0) scaleY(0.9)",
            },
            {
                offset: 0.45,
                opacity: 1,
                transform: "translate3d(0, 20px, 0) scaleY(0.9)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "translate3d(0, -2000px, 0) scaleY(3)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// ============== Fade ==============
// ==================================
/**
 * animate fadeIn
 * usage1 : <element ref={fadeIn(playback)}></element>
 * usage2 : <element ref={fadeIn(playback, 3000)}></element>
 * usage3 : <element ref={fadeIn(playback, 3000, 2000)}></element>
 */
export const fadeIn = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
            },
            {
                offset: 1,
                opacity: 1,
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInDown
 * usage1 : <element ref={fadeInDown(playback)}></element>
 * usage2 : <element ref={fadeInDown(playback, 3000)}></element>
 * usage3 : <element ref={fadeInDown(playback, 3000, 2000)}></element>
 */
export const fadeInDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(0, -100%, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInDownBig
 * usage1 : <element ref={fadeInDownBig(playback)}></element>
 * usage2 : <element ref={fadeInDownBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeInDownBig(playback, 3000, 2000)}></element>
 */
export const fadeInDownBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(0, -2000px, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInLeft
 * usage1 : <element ref={fadeInLeft(playback)}></element>
 * usage2 : <element ref={fadeInLeft(playback, 3000)}></element>
 * usage3 : <element ref={fadeInLeft(playback, 3000, 2000)}></element>
 */
export const fadeInLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(-100%, 0, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInLeftBig
 * usage1 : <element ref={fadeInLeftBig(playback)}></element>
 * usage2 : <element ref={fadeInLeftBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeInLeftBig(playback, 3000, 2000)}></element>
 */
export const fadeInLeftBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(-2000px, 0, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInRight
 * usage1 : <element ref={fadeInRight(playback)}></element>
 * usage2 : <element ref={fadeInRight(playback, 3000)}></element>
 * usage3 : <element ref={fadeInRight(playback, 3000, 2000)}></element>
 */
export const fadeInRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(100%, 0, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInRightBig
 * usage1 : <element ref={fadeInRightBig(playback)}></element>
 * usage2 : <element ref={fadeInRightBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeInRightBig(playback, 3000, 2000)}></element>
 */
export const fadeInRightBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(2000px, 0, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInUp
 * usage1 : <element ref={fadeInUp(playback)}></element>
 * usage2 : <element ref={fadeInUp(playback, 3000)}></element>
 * usage3 : <element ref={fadeInUp(playback, 3000, 2000)}></element>
 */
export const fadeInUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(0, 100%, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInUpBig
 * usage1 : <element ref={fadeInUpBig(playback)}></element>
 * usage2 : <element ref={fadeInUpBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeInUpBig(playback, 3000, 2000)}></element>
 */
export const fadeInUpBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(0, 2000px, 0)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInTopLeft
 * usage1 : <element ref={fadeInTopLeft(playback)}></element>
 * usage2 : <element ref={fadeInTopLeft(playback, 3000)}></element>
 * usage3 : <element ref={fadeInTopLeft(playback, 3000, 2000)}></element>
 */
export const fadeInTopLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 0,
            transform: "translate3d(-100%, -100%, 0)",
        }, {
            offset: 1,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInTopRight
 * usage1 : <element ref={fadeInTopRight(playback)}></element>
 * usage2 : <element ref={fadeInTopRight(playback, 3000)}></element>
 * usage3 : <element ref={fadeInTopRight(playback, 3000, 2000)}></element>
 */
export const fadeInTopRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 0,
            transform: "translate3d(100%, -100%, 0)",
        }, {
            offset: 1,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInBottomLeft
 * usage1 : <element ref={fadeInBottomLeft(playback)}></element>
 * usage2 : <element ref={fadeInBottomLeft(playback, 3000)}></element>
 * usage3 : <element ref={fadeInBottomLeft(playback, 3000, 2000)}></element>
 */
export const fadeInBottomLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 0,
            transform: "translate3d(-100%, 100%, 0)",
        }, {
            offset: 1,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeInBottomRight
 * usage1 : <element ref={fadeInBottomRight(playback)}></element>
 * usage2 : <element ref={fadeInBottomRight(playback, 3000)}></element>
 * usage3 : <element ref={fadeInBottomRight(playback, 3000, 2000)}></element>
 */
export const fadeInBottomRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 0,
            transform: "translate3d(100%, 100%, 0)",
        }, {
            offset: 1,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOut
 * usage1 : <element ref={fadeOut(playback)}></element>
 * usage2 : <element ref={fadeOut(playback, 3000)}></element>
 * usage3 : <element ref={fadeOut(playback, 3000, 2000)}></element>
 */
export const fadeOut = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutDown
 * usage1 : <element ref={fadeOutDown(playback)}></element>
 * usage2 : <element ref={fadeOutDown(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutDown(playback, 3000, 2000)}></element>
 */
export const fadeOutDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(0, 100%, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutDownBig
 * usage1 : <element ref={fadeOutDownBig(playback)}></element>
 * usage2 : <element ref={fadeOutDownBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutDownBig(playback, 3000, 2000)}></element>
 */
export const fadeOutDownBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(0, 2000px, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutLeft
 * usage1 : <element ref={fadeOutLeft(playback)}></element>
 * usage2 : <element ref={fadeOutLeft(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutLeft(playback, 3000, 2000)}></element>
 */
export const fadeOutLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(-100%, 0, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutLeftBig
 * usage1 : <element ref={fadeOutLeftBig(playback)}></element>
 * usage2 : <element ref={fadeOutLeftBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutLeftBig(playback, 3000, 2000)}></element>
 */
export const fadeOutLeftBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(-2000px, 0, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutRight
 * usage1 : <element ref={fadeOutRight(playback)}></element>
 * usage2 : <element ref={fadeOutRight(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutRight(playback, 3000, 2000)}></element>
 */
export const fadeOutRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(100%, 0, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutRightBig
 * usage1 : <element ref={fadeOutRightBig(playback)}></element>
 * usage2 : <element ref={fadeOutRightBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutRightBig(playback, 3000, 2000)}></element>
 */
export const fadeOutRightBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(2000px, 0, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutUp
 * usage1 : <element ref={fadeOutUp(playback)}></element>
 * usage2 : <element ref={fadeOutUp(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutUp(playback, 3000, 2000)}></element>
 */
export const fadeOutUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(0, -100%, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutUpBig
 * usage1 : <element ref={fadeOutUpBig(playback)}></element>
 * usage2 : <element ref={fadeOutUpBig(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutUpBig(playback, 3000, 2000)}></element>
 */
export const fadeOutUpBig = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(0, -2000px, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutTopLeft
 * usage1 : <element ref={fadeOutTopLeft(playback)}></element>
 * usage2 : <element ref={fadeOutTopLeft(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutTopLeft(playback, 3000, 2000)}></element>
 */
export const fadeOutTopLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(-100%, -100%, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutTopRight
 * usage1 : <element ref={fadeOutTopRight(playback)}></element>
 * usage2 : <element ref={fadeOutTopRight(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutTopRight(playback, 3000, 2000)}></element>
 */
export const fadeOutTopRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(100%, -100%, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutBottomRight
 * usage1 : <element ref={fadeOutBottomRight(playback)}></element>
 * usage2 : <element ref={fadeOutBottomRight(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutBottomRight(playback, 3000, 2000)}></element>
 */
export const fadeOutBottomRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(100%, 100%, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate fadeOutBottomLeft
 * usage1 : <element ref={fadeOutBottomLeft(playback)}></element>
 * usage2 : <element ref={fadeOutBottomLeft(playback, 3000)}></element>
 * usage3 : <element ref={fadeOutBottomLeft(playback, 3000, 2000)}></element>
 */
export const fadeOutBottomLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
        }, {
            offset: 1,
            opacity: 0,
            transform: "translate3d(-100%, 100%, 0)",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}




// ==================================
// ============== Flip ==============
// ==================================
/**
 * animate flip
 * usage1 : <element ref={flip(playback)}></element>
 * usage2 : <element ref={flip(playback, 3000)}></element>
 * usage3 : <element ref={flip(playback, 3000, 2000)}></element>
 */
export const flip = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg)",
                "animation-timing-function": "ease-out",
                "backface-visibility": "visible",
            },
            {
                offset: 0.4,
                transform: "perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg)",
                "animation-timing-function": "ease-out",
                "backface-visibility": "visible",
            },
            {
                offset: 0.5,
                transform: "perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg)",
                "animation-timing-function": "ease-in",
                "backface-visibility": "visible",
            },
            {
                offset: 0.8,
                transform: "perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg)",
                "animation-timing-function": "ease-in",
                "backface-visibility": "visible",
            },
            {
                offset: 1,
                transform: "perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg)",
                "animation-timing-function": "ease-in",
                "backface-visibility": "visible",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate flipInX
 * usage1 : <element ref={flipInX(playback)}></element>
 * usage2 : <element ref={flipInX(playback, 3000)}></element>
 * usage3 : <element ref={flipInX(playback, 3000, 2000)}></element>
 */
export const flipInX = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "perspective(400px) rotate3d(1, 0, 0, 90deg)",
                "animation-timing-function": "ease-in",
                opacity: 0,
                "backface-visibility": "visible",
            },
            {
                offset: 0.4,
                transform: "perspective(400px) rotate3d(1, 0, 0, -20deg)",
                "animation-timing-function": "ease-in",
                "backface-visibility": "visible",
            },
            {
                offset: 0.6,
                transform: "perspective(400px) rotate3d(1, 0, 0, 10deg)",
                opacity: 1,
                "backface-visibility": "visible",
            },
            {
                offset: 0.8,
                transform: "perspective(400px) rotate3d(1, 0, 0, -5deg)",
                "backface-visibility": "visible",
            },
            {
                offset: 1,
                transform: "perspective(400px)",
                "backface-visibility": "visible",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate flipOutX
 * usage1 : <element ref={flipOutX(playback)}></element>
 * usage2 : <element ref={flipOutX(playback, 3000)}></element>
 * usage3 : <element ref={flipOutX(playback, 3000, 2000)}></element>
 */
export const flipOutX = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "perspective(400px)",
                "backface-visibility": "visible",
            },
            {
                offset: 0.3,
                transform: "perspective(400px) rotate3d(1, 0, 0, -20deg)",
                opacity: 1,
                "backface-visibility": "visible",
            },
            {
                offset: 1,
                transform: "perspective(400px) rotate3d(1, 0, 0, 90deg)",
                opacity: 0,
                "backface-visibility": "visible",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate flipInY
 * usage1 : <element ref={flipInY(playback)}></element>
 * usage2 : <element ref={flipInY(playback, 3000)}></element>
 * usage3 : <element ref={flipInY(playback, 3000, 2000)}></element>
 */
export const flipInY = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "perspective(400px) rotate3d(0, 1, 0, 90deg)",
                "animation-timing-function": "ease-in",
                opacity: 0,
                "backface-visibility": "visible",
            },
            {
                offset: 0.4,
                transform: "perspective(400px) rotate3d(0, 1, 0, -20deg)",
                "animation-timing-function": "ease-in",
                "backface-visibility": "visible",
            },
            {
                offset: 0.6,
                transform: "perspective(400px) rotate3d(0, 1, 0, 10deg)",
                opacity: 1,
                "backface-visibility": "visible",
            },
            {
                offset: 0.8,
                transform: "perspective(400px) rotate3d(0, 1, 0, -5deg)",
                "backface-visibility": "visible",
            },
            {
                offset: 1,
                transform: "perspective(400px)",
                "backface-visibility": "visible",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate flipOutY
 * usage1 : <element ref={flipOutY(playback)}></element>
 * usage2 : <element ref={flipOutY(playback, 3000)}></element>
 * usage3 : <element ref={flipOutY(playback, 3000, 2000)}></element>
 */
export const flipOutY = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "perspective(400 px)",
                "backface-visibility": "visible",
            },
            {
                offset: 0.3,
                transform: "perspective(400 px) rotate3d(0, 1, 0, -15 deg)",
                opacity: 1,
                "backface-visibility": "visible",
            },
            {
                offset: 1,
                transform: "perspective(400 px) rotate3d(0, 1, 0, 90 deg)",
                opacity: 0,
                "backface-visibility": "visible",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// =========== Lightspeed ===========
// ==================================
/**
 * animate lightSpeedInRight
 * usage1 : <element ref={lightSpeedInRight(playback)}></element>
 * usage2 : <element ref={lightSpeedInRight(playback, 3000)}></element>
 * usage3 : <element ref={lightSpeedInRight(playback, 3000, 2000)}></element>
 */
export const lightSpeedInRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "ease-out",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(100%, 0, 0) skewX(-30deg)",
                opacity: 0,
            },
            {
                offset: 0.6,
                transform: "skewX(20deg)",
                opacity: 1,
            },
            {
                offset: 0.8,
                transform: "skewX(-5deg)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate lightSpeedInLeft
 * usage1 : <element ref={lightSpeedInLeft(playback)}></element>
 * usage2 : <element ref={lightSpeedInLeft(playback, 3000)}></element>
 * usage3 : <element ref={lightSpeedInLeft(playback, 3000, 2000)}></element>
 */
export const lightSpeedInLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "ease-out",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(-100%, 0, 0) skewX(30deg)",
                opacity: 0,
            },
            {
                offset: 0.6,
                transform: "skewX(-20deg)",
                opacity: 1,
            },
            {
                offset: 0.8,
                transform: "skewX(5deg)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate lightSpeedOutRight
 * usage1 : <element ref={lightSpeedOutRight(playback)}></element>
 * usage2 : <element ref={lightSpeedOutRight(playback, 3000)}></element>
 * usage3 : <element ref={lightSpeedOutRight(playback, 3000, 2000)}></element>
 */
export const lightSpeedOutRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "ease-out",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 1,
            },
            {
                offset: 1,
                transform: "translate3d(100%, 0, 0) skewX(30deg)",
                opacity: 0,
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate lightSpeedOutLeft
 * usage1 : <element ref={lightSpeedOutLeft(playback)}></element>
 * usage2 : <element ref={lightSpeedOutLeft(playback, 3000)}></element>
 * usage3 : <element ref={lightSpeedOutLeft(playback, 3000, 2000)}></element>
 */
export const lightSpeedOutLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "ease-out",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 1,
            },
            {
                offset: 1,
                transform: "translate3d(-100%, 0, 0) skewX(-30deg)",
                opacity: 0,
            },
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// ============ Rotating ============
// ==================================
/**
 * animate rotateIn
 * usage1 : <element ref={rotateIn(playback)}></element>
 * usage2 : <element ref={rotateIn(playback, 3000)}></element>
 * usage3 : <element ref={rotateIn(playback, 3000, 2000)}></element>
 */
export const rotateIn = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            transform: "rotate3d(0, 0, 1, -200deg)",
            opacity: 0,
            "transform-origin": "center",
        }, {
            offset: 1,
            transform: "translate3d(0, 0, 0)",
            opacity: 1,
            "transform-origin": "center",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateInDownLeft
 * usage1 : <element ref={rotateInDownLeft(playback)}></element>
 * usage2 : <element ref={rotateInDownLeft(playback, 3000)}></element>
 * usage3 : <element ref={rotateInDownLeft(playback, 3000, 2000)}></element>
 */
export const rotateInDownLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            transform: "rotate3d(0, 0, 1, -45deg)",
            opacity: 0,
            "transform-origin": "left bottom",
        }, {
            offset: 1,
            transform: "translate3d(0, 0, 0)",
            opacity: 1,
            "transform-origin": "left bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateInDownRight
 * usage1 : <element ref={rotateInDownRight(playback)}></element>
 * usage2 : <element ref={rotateInDownRight(playback, 3000)}></element>
 * usage3 : <element ref={rotateInDownRight(playback, 3000, 2000)}></element>
 */
export const rotateInDownRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            transform: "rotate3d(0, 0, 1, 45deg)",
            opacity: 0,
            "transform-origin": "right bottom",
        }, {
            offset: 1,
            transform: "translate3d(0, 0, 0)",
            opacity: 1,
            "transform-origin": "right bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateInUpLeft
 * usage1 : <element ref={rotateInUpLeft(playback)}></element>
 * usage2 : <element ref={rotateInUpLeft(playback, 3000)}></element>
 * usage3 : <element ref={rotateInUpLeft(playback, 3000, 2000)}></element>
 */
export const rotateInUpLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            transform: "rotate3d(0, 0, 1, 45deg)",
            opacity: 0,
            "transform-origin": "left bottom",
        }, {
            offset: 1,
            transform: "translate3d(0, 0, 0)",
            opacity: 1,
            "transform-origin": "left bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateInUpRight
 * usage1 : <element ref={rotateInUpRight(playback)}></element>
 * usage2 : <element ref={rotateInUpRight(playback, 3000)}></element>
 * usage3 : <element ref={rotateInUpRight(playback, 3000, 2000)}></element>
 */
export const rotateInUpRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            transform: "rotate3d(0, 0, 1, -90deg)",
            opacity: 0,
            "transform-origin": "right bottom",
        }, {
            offset: 1,
            transform: "translate3d(0, 0, 0)",
            opacity: 1,
            "transform-origin": "right bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateOut
 * usage1 : <element ref={rotateOut(playback)}></element>
 * usage2 : <element ref={rotateOut(playback, 3000)}></element>
 * usage3 : <element ref={rotateOut(playback, 3000, 2000)}></element>
 */
export const rotateOut = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            "transform-origin": "center",
        }, {
            offset: 1,
            transform: "rotate3d(0, 0, 1, 200deg)",
            opacity: 0,
            "transform-origin": "center",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateOutDownLeft
 * usage1 : <element ref={rotateOutDownLeft(playback)}></element>
 * usage2 : <element ref={rotateOutDownLeft(playback, 3000)}></element>
 * usage3 : <element ref={rotateOutDownLeft(playback, 3000, 2000)}></element>
 */
export const rotateOutDownLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            "transform-origin": "left bottom",
        }, {
            offset: 1,
            transform: "rotate3d(0, 0, 1, 45deg)",
            opacity: 0,
            "transform-origin": "left bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateOutDownRight
 * usage1 : <element ref={rotateOutDownRight(playback)}></element>
 * usage2 : <element ref={rotateOutDownRight(playback, 3000)}></element>
 * usage3 : <element ref={rotateOutDownRight(playback, 3000, 2000)}></element>
 */
export const rotateOutDownRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            "transform-origin": "right bottom",
        }, {
            offset: 1,
            transform: "rotate3d(0, 0, 1, -45deg)",
            opacity: 0,
            "transform-origin": "right bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateOutUpLeft
 * usage1 : <element ref={rotateOutUpLeft(playback)}></element>
 * usage2 : <element ref={rotateOutUpLeft(playback, 3000)}></element>
 * usage3 : <element ref={rotateOutUpLeft(playback, 3000, 2000)}></element>
 */
export const rotateOutUpLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            "transform-origin": "left bottom",
        }, {
            offset: 1,
            transform: "rotate3d(0, 0, 1, -45deg)",
            opacity: 0,
            "transform-origin": "left bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rotateOutUpRight
 * usage1 : <element ref={rotateOutUpRight(playback)}></element>
 * usage2 : <element ref={rotateOutUpRight(playback, 3000)}></element>
 * usage3 : <element ref={rotateOutUpRight(playback, 3000, 2000)}></element>
 */
export const rotateOutUpRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
            offset: 0,
            opacity: 1,
            "transform-origin": "right bottom",
        }, {
            offset: 1,
            transform: "rotate3d(0, 0, 1, 90deg)",
            opacity: 0,
            "transform-origin": "right bottom",
        }], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// ============ Specials ============
// ==================================
/**
 * animate hinge
 * usage1 : <element ref={hinge(playback)}></element>
 * usage2 : <element ref={hinge(playback, 3000)}></element>
 * usage3 : <element ref={hinge(playback, 3000, 2000)}></element>
 */
export const hinge = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                "animation-timing-function": "ease-in-out",
                "transform-origin": "top left",
            },
            {
                offset: 0.2,
                transform: "rotate3d(0, 0, 1, 80deg)",
                "animation-timing-function": "ease-in-out",
                "transform-origin": "top left",
            },
            {
                offset: 0.4,
                transform: "rotate3d(0, 0, 1, 60deg)",
                "animation-timing-function": "ease-in-out",
                "transform-origin": "top left",
                opacity: 1,
            },
            {
                offset: 0.6,
                transform: "rotate3d(0, 0, 1, 80deg)",
                "animation-timing-function": "ease-in-out",
                "transform-origin": "top left",
            },
            {
                offset: 0.8,
                transform: "rotate3d(0, 0, 1, 60deg)",
                "animation-timing-function": "ease-in-out",
                "transform-origin": "top left",
                opacity: 1,
            },
            {
                offset: 1,
                transform: "translate3d(0, 700px, 0)",
                "transform-origin": "top left",
                opacity: 0,
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate jackInTheBox
 * usage1 : <element ref={jackInTheBox(playback)}></element>
 * usage2 : <element ref={jackInTheBox(playback, 3000)}></element>
 * usage3 : <element ref={jackInTheBox(playback, 3000, 2000)}></element>
 */
export const jackInTheBox = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale(0.1) rotate(30deg)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.5,
                transform: "rotate(-10deg)",
            },
            {
                offset: 0.7,
                transform: "rotate(3deg)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "scale(1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rollIn
 * usage1 : <element ref={rollIn(playback)}></element>
 * usage2 : <element ref={rollIn(playback, 3000)}></element>
 * usage3 : <element ref={rollIn(playback, 3000, 2000)}></element>
 */
export const rollIn = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)",
            },
            {
                offset: 1,
                opacity: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rollOut
 * usage1 : <element ref={rollOut(playback)}></element>
 * usage2 : <element ref={rollOut(playback, 3000)}></element>
 * usage3 : <element ref={rollOut(playback, 3000, 2000)}></element>
 */
export const rollOut = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 1,
            },
            {
                offset: 1,
                opacity: 0,
                transform: "translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// ============ Zooming ============
// ==================================
/**
 * animate zoomIn
 * usage1 : <element ref={zoomIn(playback)}></element>
 * usage2 : <element ref={zoomIn(playback, 3000)}></element>
 * usage3 : <element ref={zoomIn(playback, 3000, 2000)}></element>
 */
export const zoomIn = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale3d(0.3, 0.3, 0.3)",
            },
            {
                offset: 0.5,
                opacity: 1,
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomInDown
 * usage1 : <element ref={zoomInDown(playback)}></element>
 * usage2 : <element ref={zoomInDown(playback, 3000)}></element>
 * usage3 : <element ref={zoomInDown(playback, 3000, 2000)}></element>
 */
export const zoomInDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0)",
                "animation-timing-function": "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)",
                "animation-timing-function": "cubic-bezier(0.175, 0.885, 0.32, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomInLeft
 * usage1 : <element ref={zoomInLeft(playback)}></element>
 * usage2 : <element ref={zoomInLeft(playback, 3000)}></element>
 * usage3 : <element ref={zoomInLeft(playback, 3000, 2000)}></element>
 */
export const zoomInLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.175, 0.885, 0.32, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomInRight
 * usage1 : <element ref={zoomInRight(playback)}></element>
 * usage2 : <element ref={zoomInRight(playback, 3000)}></element>
 * usage3 : <element ref={zoomInRight(playback, 3000, 2000)}></element>
 */
export const zoomInRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0)",
                "animation-timing-function": "cubic-bezier(0.175, 0.885, 0.32, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomInUp
 * usage1 : <element ref={zoomInUp(playback)}></element>
 * usage2 : <element ref={zoomInUp(playback, 3000)}></element>
 * usage3 : <element ref={zoomInUp(playback, 3000, 2000)}></element>
 */
export const zoomInUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 0,
                transform: "scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0)",
                "animation-timing-function": "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            },
            {
                offset: 0.6,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)",
                "animation-timing-function": "cubic-bezier(0.175, 0.885, 0.32, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomOut
 * usage1 : <element ref={zoomOut(playback)}></element>
 * usage2 : <element ref={zoomOut(playback, 3000)}></element>
 * usage3 : <element ref={zoomOut(playback, 3000, 2000)}></element>
 */
export const zoomOut = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 1,
            },
            {
                offset: 0.5,
                opacity: 0,
                transform: "scale3d(0.3, 0.3, 0.3)",
            },
            {
                offset: 1,
                opacity: 0,
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomOutDown
 * usage1 : <element ref={zoomOutDown(playback)}></element>
 * usage2 : <element ref={zoomOutDown(playback, 3000)}></element>
 * usage3 : <element ref={zoomOutDown(playback, 3000, 2000)}></element>
 */
export const zoomOutDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [
            {
                offset: 0,
                "transform-origin": "center bottom",
            },
            {
                offset: 0.4,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)",
                "animation-timing-function": "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0)",
                "animation-timing-function": "cubic-bezier(0.175, 0.885, 0.32, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomOutLeft
 * usage1 : <element ref={zoomOutLeft(playback)}></element>
 * usage2 : <element ref={zoomOutLeft(playback, 3000)}></element>
 * usage3 : <element ref={zoomOutLeft(playback, 3000, 2000)}></element>
 */
export const zoomOutLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [
            {
                offset: 0,
                "transform-origin": "left center",
            },
            {
                offset: 0.4,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "scale(0.1) translate3d(-2000px, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomOutRight
 * usage1 : <element ref={zoomOutRight(playback)}></element>
 * usage2 : <element ref={zoomOutRight(playback, 3000)}></element>
 * usage3 : <element ref={zoomOutRight(playback, 3000, 2000)}></element>
 */
export const zoomOutRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [
            {
                offset: 0,
                "transform-origin": "right center",
            },
            {
                offset: 0.4,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "scale(0.1) translate3d(2000px, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate zoomOutUp
 * usage1 : <element ref={zoomOutUp(playback)}></element>
 * usage2 : <element ref={zoomOutUp(playback, 3000)}></element>
 * usage3 : <element ref={zoomOutUp(playback, 3000, 2000)}></element>
 */
export const zoomOutUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [
            {
                offset: 0,
                "transform-origin": "center bottom",
            },
            {
                offset: 0.4,
                opacity: 1,
                transform: "scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)",
                "animation-timing-function": "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            },
            {
                offset: 1,
                opacity: 0,
                transform: "scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0)",
                "animation-timing-function": "cubic-bezier(0.175, 0.885, 0.32, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// ============ Sliding ============
// ==================================
/**
 * animate slideInDown
 * usage1 : <element ref={slideInDown(playback)}></element>
 * usage2 : <element ref={slideInDown(playback, 3000)}></element>
 * usage3 : <element ref={slideInDown(playback, 3000, 2000)}></element>
 */
export const slideInDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, -100%, 0)",
                visibility: "visible",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideInLeft
 * usage1 : <element ref={slideInLeft(playback)}></element>
 * usage2 : <element ref={slideInLeft(playback, 3000)}></element>
 * usage3 : <element ref={slideInLeft(playback, 3000, 2000)}></element>
 */
export const slideInLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(-100%, 0, 0)",
                visibility: "visible",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideInRight
 * usage1 : <element ref={slideInRight(playback)}></element>
 * usage2 : <element ref={slideInRight(playback, 3000)}></element>
 * usage3 : <element ref={slideInRight(playback, 3000, 2000)}></element>
 */
export const slideInRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(100%, 0, 0)",
                visibility: "visible",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideInUp
 * usage1 : <element ref={slideInUp(playback)}></element>
 * usage2 : <element ref={slideInUp(playback, 3000)}></element>
 * usage3 : <element ref={slideInUp(playback, 3000, 2000)}></element>
 */
export const slideInUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 100%, 0)",
                visibility: "visible",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideOutDown
 * usage1 : <element ref={slideOutDown(playback)}></element>
 * usage2 : <element ref={slideOutDown(playback, 3000)}></element>
 * usage3 : <element ref={slideOutDown(playback, 3000, 2000)}></element>
 */
export const slideOutDown = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 1,
                visibility: "hidden",
                transform: "translate3d(0, 100%, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideOutLeft
 * usage1 : <element ref={slideOutLeft(playback)}></element>
 * usage2 : <element ref={slideOutLeft(playback, 3000)}></element>
 * usage3 : <element ref={slideOutLeft(playback, 3000, 2000)}></element>
 */
export const slideOutLeft = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 1,
                visibility: "hidden",
                transform: "translate3d(-100%, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideOutRight
 * usage1 : <element ref={slideOutRight(playback)}></element>
 * usage2 : <element ref={slideOutRight(playback, 3000)}></element>
 * usage3 : <element ref={slideOutRight(playback, 3000, 2000)}></element>
 */
export const slideOutRight = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 1,
                visibility: "hidden",
                transform: "translate3d(100%, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate slideOutUp
 * usage1 : <element ref={slideOutUp(playback)}></element>
 * usage2 : <element ref={slideOutUp(playback, 3000)}></element>
 * usage3 : <element ref={slideOutUp(playback, 3000, 2000)}></element>
 */
export const slideOutUp = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 1,
                visibility: "hidden",
                transform: "translate3d(0, -100%, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}



// ==================================
// ======= Attention seekers =======
// ==================================
/**
 * animate bounce
 * usage1 : <element ref={bounce(playback)}></element>
 * usage2 : <element ref={bounce(playback, 3000)}></element>
 * usage3 : <element ref={bounce(playback, 3000, 2000)}></element>
 */
export const bounce = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
                transform: "translate3d(0, 0, 0)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.2,
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
                transform: "translate3d(0, 0, 0)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.4,
                "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
                transform: "translate3d(0, -30px, 0) scaleY(1.1)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.43,
                "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
                transform: "translate3d(0, -30px, 0) scaleY(1.1)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.53,
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
                transform: "translate3d(0, 0, 0)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.7,
                "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
                transform: "translate3d(0, -15px, 0) scaleY(1.05)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.8,
                "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
                transform: "translate3d(0, 0, 0) scaleY(0.95)",
                "transform-origin": "center bottom",
            },
            {
                offset: 0.9,
                transform: "translate3d(0, -4px, 0) scaleY(1.02)",
                "transform-origin": "center bottom",
            },
            {
                offset: 1,
                "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
                transform: "translate3d(0, 0, 0)",
                "transform-origin": "center bottom",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate flash
 * usage1 : <element ref={flash(playback)}></element>
 * usage2 : <element ref={flash(playback, 3000)}></element>
 * usage3 : <element ref={flash(playback, 3000, 2000)}></element>
 */
export const flash = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                opacity: 1,
            },
            {
                offset: 0.25,
                opacity: 0,
            },
            {
                offset: 0.5,
                opacity: 1,
            },
            {
                offset: 0.75,
                opacity: 0,
            },
            {
                offset: 1,
                opacity: 1,
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate pulse
 * usage1 : <element ref={pulse(playback)}></element>
 * usage2 : <element ref={pulse(playback, 3000)}></element>
 * usage3 : <element ref={pulse(playback, 3000, 2000)}></element>
 */
export const pulse = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "scale3d(1, 1, 1)",
            },
            {
                offset: 0.5,
                transform: "scale3d(1.05, 1.05, 1.05)",
            },
            {
                offset: 1,
                transform: "scale3d(1, 1, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate rubberBand
 * usage1 : <element ref={rubberBand(playback)}></element>
 * usage2 : <element ref={rubberBand(playback, 3000)}></element>
 * usage3 : <element ref={rubberBand(playback, 3000, 2000)}></element>
 */
export const rubberBand = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "scale3d(1, 1, 1)",
            },
            {
                offset: 0.3,
                transform: "scale3d(1.25, 0.75, 1)",
            },
            {
                offset: 0.4,
                transform: "scale3d(0.75, 1.25, 1)",
            },
            {
                offset: 0.5,
                transform: "scale3d(1.15, 0.85, 1)",
            },
            {
                offset: 0.65,
                transform: "scale3d(0.95, 1.05, 1)",
            },
            {
                offset: 0.75,
                transform: "scale3d(1.05, 0.95, 1)",
            },
            {
                offset: 1,
                transform: "scale3d(1, 1, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate shakeX
 * usage1 : <element ref={shakeX(playback)}></element>
 * usage2 : <element ref={shakeX(playback, 3000)}></element>
 * usage3 : <element ref={shakeX(playback, 3000, 2000)}></element>
 */
export const shakeX = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 0.1,
                transform: "translate3d(-10px, 0, 0)",
            },
            {
                offset: 0.2,
                transform: "translate3d(10px, 0, 0)",
            },
            {
                offset: 0.3,
                transform: "translate3d(-10px, 0, 0)",
            },
            {
                offset: 0.4,
                transform: "translate3d(10px, 0, 0)",
            },
            {
                offset: 0.5,
                transform: "translate3d(-10px, 0, 0)",
            },
            {
                offset: 0.6,
                transform: "translate3d(10px, 0, 0)",
            },
            {
                offset: 0.7,
                transform: "translate3d(-10px, 0, 0)",
            },
            {
                offset: 0.8,
                transform: "translate3d(10px, 0, 0)",
            },
            {
                offset: 0.9,
                transform: "translate3d(-10px, 0, 0)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate shakeY
 * usage1 : <element ref={shakeY(playback)}></element>
 * usage2 : <element ref={shakeY(playback, 3000)}></element>
 * usage3 : <element ref={shakeY(playback, 3000, 2000)}></element>
 */
export const shakeY = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 0.1,
                transform: "translate3d(0, -10px, 0)",
            },
            {
                offset: 0.2,
                transform: "translate3d(0, 10px, 0)",
            },
            {
                offset: 0.3,
                transform: "translate3d(0, -10px, 0)",
            },
            {
                offset: 0.4,
                transform: "translate3d(0, 10px, 0)",
            },
            {
                offset: 0.5,
                transform: "translate3d(0, -10px, 0)",
            },
            {
                offset: 0.6,
                transform: "translate3d(0, 10px, 0)",
            },
            {
                offset: 0.7,
                transform: "translate3d(0, -10px, 0)",
            },
            {
                offset: 0.8,
                transform: "translate3d(0, 10px, 0)",
            },
            {
                offset: 0.9,
                transform: "translate3d(0, -10px, 0)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate headShake
 * usage1 : <element ref={headShake(playback)}></element>
 * usage2 : <element ref={headShake(playback, 3000)}></element>
 * usage3 : <element ref={headShake(playback, 3000, 2000)}></element>
 */
export const headShake = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translateX(0)",
            },
            {
                offset: 0.065,
                transform: "translateX(-6px) rotateY(-9deg)",
            },
            {
                offset: 0.185,
                transform: "translateX(5px) rotateY(7deg)",
            },
            {
                offset: 0.315,
                transform: "translateX(-3px) rotateY(-5deg)",
            },
            {
                offset: 0.435,
                transform: "translateX(2px) rotateY(3deg)",
            },
            {
                offset: 0.5,
                transform: "translateX(0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate swing
 * usage1 : <element ref={swing(playback)}></element>
 * usage2 : <element ref={swing(playback, 3000)}></element>
 * usage3 : <element ref={swing(playback, 3000, 2000)}></element>
 */
export const swing = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0.2,
                transform: "rotate3d(0, 0, 1, 15deg)",
            },
            {
                offset: 0.4,
                transform: "rotate3d(0, 0, 1, -10deg)",
            },
            {
                offset: 0.6,
                transform: "rotate3d(0, 0, 1, 5deg)",
            },
            {
                offset: 0.8,
                transform: "rotate3d(0, 0, 1, -5deg)",
            },
            {
                offset: 1,
                transform: "rotate3d(0, 0, 1, 0deg)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate tada
 * usage1 : <element ref={tada(playback)}></element>
 * usage2 : <element ref={tada(playback, 3000)}></element>
 * usage3 : <element ref={tada(playback, 3000, 2000)}></element>
 */
export const tada = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "scale3d(1, 1, 1)",
            },
            {
                offset: 0.1,
                transform: "scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)",
            },
            {
                offset: 0.2,
                transform: "scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)",
            },
            {
                offset: 0.3,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)",
            },
            {
                offset: 0.4,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)",
            },
            {
                offset: 0.5,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)",
            },
            {
                offset: 0.6,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)",
            },
            {
                offset: 0.7,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)",
            },
            {
                offset: 0.8,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)",
            },
            {
                offset: 0.9,
                transform: "scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)",
            },
            {
                offset: 1,
                transform: "scale3d(1, 1, 1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate wobble
 * usage1 : <element ref={wobble(playback)}></element>
 * usage2 : <element ref={wobble(playback, 3000)}></element>
 * usage3 : <element ref={wobble(playback, 3000, 2000)}></element>
 */
export const wobble = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                transform: "translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)",
            },
            {
                offset: 0.3,
                transform: "translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)",
            },
            {
                offset: 0.45,
                transform: "translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)",
            },
            {
                offset: 0.6,
                transform: "translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)",
            },
            {
                offset: 0.75,
                transform: "translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate jello
 * usage1 : <element ref={jello(playback)}></element>
 * usage2 : <element ref={jello(playback, 3000)}></element>
 * usage3 : <element ref={jello(playback, 3000, 2000)}></element>
 */
export const jello = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 0.111,
                transform: "translate3d(0, 0, 0)",
            },
            {
                offset: 0.222,
                transform: "skewX(-12.5deg) skewY(-12.5deg)",
            },
            {
                offset: 0.333,
                transform: "skewX(6.25deg) skewY(6.25deg)",
            },
            {
                offset: 0.444,
                transform: "skewX(-3.125deg) skewY(-3.125deg)",
            },
            {
                offset: 0.555,
                transform: "skewX(1.5625deg) skewY(1.5625deg)",
            },
            {
                offset: 0.666,
                transform: "skewX(-0.78125deg) skewY(-0.78125deg)",
            },
            {
                offset: 0.777,
                transform: "skewX(0.390625deg) skewY(0.390625deg)",
            },
            {
                offset: 0.888,
                transform: "skewX(-0.1953125deg) skewY(-0.1953125deg)",
            },
            {
                offset: 1,
                transform: "translate3d(0, 0, 0)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}

/**
 * animate heartBeat
 * usage1 : <element ref={heartBeat(playback)}></element>
 * usage2 : <element ref={heartBeat(playback, 3000)}></element>
 * usage3 : <element ref={heartBeat(playback, 3000, 2000)}></element>
 */
export const heartBeat = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [{
                offset: 0,
                transform: "scale(1)",
            },
            {
                offset: 0.14,
                transform: "scale(1.3)",
            },
            {
                offset: 0.28,
                transform: "scale(1)",
            },
            {
                offset: 0.42,
                transform: "scale(1.3)",
            },
            {
                offset: 0.7,
                transform: "scale(1)",
            }
        ], {
            delay,
            duration,
            easing,
            fill
        }
    );
}


/**
 * animate xxxxxxx
 * usage1 : <element ref={xxxxxxx(playback)}></element>
 * usage2 : <element ref={xxxxxxx(playback, 3000)}></element>
 * usage3 : <element ref={xxxxxxx(playback, 3000, 2000)}></element>
 */
/*export const xxxxxxx = (
    playback,
    delay = 0,
    duration = 1000,
    easing = "linear",
    fill = "both",
) => {
    return playback.newAnimation(
        [yyyyyyy], {
            delay,
            duration,
            easing,
            fill
        }
    );
}*/