import { Script } from "liqvid";

export const markers_scene1_intro = [
	//[name, startTime, endTime], //(syntaxe)
    ["video/", "0:0", "0:34"],
    ["video/title", "0:34", "0:40"],
    ["video/choices", "0:40", "0:50"],
];
export const script_scene1_intro = new Script(markers_scene1_intro);
export const playback_scene1_intro = script_scene1_intro.playback;


export const markers_scene1_choice1 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:14"],
    ["video/result", "0:14", "0:22"],
    ["video/redirection", "0:22", "0:23"],
];
export const script_scene1_choice1 = new Script(markers_scene1_choice1);
export const playback_scene1_choice1 = script_scene1_choice1.playback;


export const markers_scene1_choice2 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:8"],
    ["video/result", "0:8", "0:16"],
    ["video/choices", "0:16", "0:26"],
];
export const script_scene1_choice2 = new Script(markers_scene1_choice2);
export const playback_scene1_choice2 = script_scene1_choice2.playback;


export const markers_scene1_choice3 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:25"],
    ["video/result", "0:25", "0:32"],
    ["video/choices", "0:32", "0:42"],
];
export const script_scene1_choice3 = new Script(markers_scene1_choice3);
export const playback_scene1_choice3 = script_scene1_choice3.playback;