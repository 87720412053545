import { Script } from "liqvid";

export const markers_scene2_intro = [
	//[name, startTime, endTime], //(syntaxe)
    ["video/", "0:0", "0:14"],
    ["video/title", "0:14", "0:20"],
    ["video/choices", "0:20", "0:30"],
];
export const script_scene2_intro = new Script(markers_scene2_intro);
export const playback_scene2_intro = script_scene2_intro.playback;


export const markers_scene2_choice1 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:38"],
    ["video/result", "0:38", "0:46"],
    ["video/redirection", "0:46", "0:47"],
];
export const script_scene2_choice1 = new Script(markers_scene2_choice1);
export const playback_scene2_choice1 = script_scene2_choice1.playback;


export const markers_scene2_choice2 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:06"],
    ["video/result", "0:06", "0:14"],
    ["video/choices", "0:14", "0:24"],
];
export const script_scene2_choice2 = new Script(markers_scene2_choice2);
export const playback_scene2_choice2 = script_scene2_choice2.playback;


export const markers_scene2_choice3 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:15"],
    ["video/result", "0:15", "0:23"],
    ["video/choices", "0:23", "0:33"],
];
export const script_scene2_choice3 = new Script(markers_scene2_choice3);
export const playback_scene2_choice3 = script_scene2_choice3.playback;