import { Controls, useKeymap, usePlayback, Utils } from "liqvid";
import "./CustomControls.scss";
import React from "react";

import PlayList from "../PlayList/PlayList";

const strings = {
    EXIT_FULL_SCREEN: "Exit full screen",
    ENTER_FULL_SCREEN: "Full screen",
    MUTE: "Mute",
    UNMUTE: "Unmute",
    PAUSE: "Pause",
    PLAY: "Play",
};

/** Play icon */
const playIcon = (
    <svg
        viewBox="0 0 24 24"
        width="36"
        height="36"
        stroke="#FFFFFF"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="play"
    >
        <polygon points="5 3 19 12 5 21 5 3"></polygon>
    </svg>
);
/** Pause icon */
const pauseIcon = (
    <svg
        viewBox="0 0 24 24"
        width="36"
        height="36"
        stroke="#FFFFFF"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="pause"
    >
        <rect x="6" y="4" width="4" height="16"></rect>
        <rect x="14" y="4" width="4" height="16"></rect>
    </svg>
);
/** Stop icon */
const stopIcon = (
    <svg
        viewBox="0 0 24 24"
        width="36"
        height="36"
        stroke="#FFFFFF"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stop"
    >
        <polyline points="1 4 1 10 7 10"></polyline>
        <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
    </svg>
);

function PlayPause() {
    const onClick$1 = Utils.react.onClick;
    const keymap = useKeymap();
    const playback = usePlayback();
    const forceUpdate = Utils.react.useForceUpdate();
    (0, React.useEffect)(() => {
        // subscribe to events
        const events = ["pause", "play", "seeking", "seeked", "stop"];
        for (const e of events) playback.on(e, forceUpdate);
        // keyboard controls
        const toggle = () => playback[playback.paused ? "play" : "pause"]();
        keymap.bind("K", toggle);
        keymap.bind("Space", toggle);
        return () => {
            // unbind playback listeners
            for (const e of events) playback.off(e, forceUpdate);
            // unbind keyboard controls
            keymap.unbind("K", toggle);
            keymap.unbind("Space", toggle);
        };
    }, []);
    // event handler
    const events = (0, React.useMemo)(
        () =>
            onClick$1(() => {
                if (playback.paused) {
                    playback.play();
                    if (playback.currentVideo) {
                        playback.currentVideo.play()
                    }
                } else {
                    playback.pause();
                    if (playback.currentVideo) {
                        playback.currentVideo.pause()
                    }
                }
            }),
        []
    );
    const label =
        (playback.paused || playback.seeking ? strings.PLAY : strings.PAUSE) +
        " (k)";

    var icon = null;
    if (playback.paused || playback.seeking) {
        if (playback.currentTime >= playback.duration) {
            icon = stopIcon;
        } else {
            icon = playIcon;
        }
    } else {
        icon = pauseIcon;
    }

    var renderPlayBig = () => {
        if (playback.paused && !(playback.currentTime >= playback.duration)) {
            return (
                <div className="big_play_wrp">
                    <button className="btn" {...events}>
                        <svg
                            viewBox="0 0 24 24"
                            width="36"
                            height="36"
                            stroke="#FFFFFF"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="play"
                        >
                            <polygon points="5 3 19 12 5 21 5 3"></polygon>
                        </svg>
                    </button>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {renderPlayBig()}
            <button
                className="lv-controls-playpause"
                aria-label={label}
                title={label}
                {...events}
            >
                {icon}
            </button>
        </>
    );
}

export const customControls = (onSceneChange = () => {}) => {
    return (
        <>
            <PlayList onSceneChange={onSceneChange} />
            <div className="el_left">
                <PlayPause />
                <Controls.Volume />
                <Controls.TimeDisplay />
            </div>
            <div className="el_right">
                <Controls.FullScreen />
            </div>
        </>
    );
};
