import React, { useState, useEffect } from "react";
import "./InteractiveVideo.scss";
import "../../liqvid/styles/liqvid.scss";

import { Intro } from "../../components/Intro/Intro";
import { Scene1 } from "../../components/Scene1/Scene1";
import { Scene2 } from "../../components/Scene2/Scene2";
import { Scene3 } from "../../components/Scene3/Scene3";
import { Outro } from "../../components/Outro/Outro";

function getDefaultCurrentScene(savedCurrentScene) {
	var dico_scenes = {
		intro: "intro",
		scene1: "scene1",
		scene1_choice1: "scene1",
		scene1_choice2: "scene1",
		scene1_choice3: "scene1",
		scene2: "scene2",
		scene2_choice1: "scene2",
		scene2_choice2: "scene2",
		scene2_choice3: "scene2",
		scene3: "scene3",
		scene3_choice1: "scene3",
		scene3_choice2: "scene3",
		scene3_choice3: "scene3",
		outro: "outro",
	};

	return dico_scenes[savedCurrentScene];
}

function InteractiveVideo() {
	const _savedCurrentScene =
		window.savedCurrentScene || "intro";

	const [currentScene, setCurrentScene] = useState(
		getDefaultCurrentScene(_savedCurrentScene)
	);

	const [isAutoplay, setIsAutoplay] = useState(window.screen.width > 768 ? true : false );

	const handleSceneChange = (scene_name, is_autoplay = true) => {
		is_autoplay = window.screen.width > 768 ? true : false
		setIsAutoplay(is_autoplay)
		setCurrentScene(scene_name);
		// save current scene
		window.savedCurrentScene = scene_name
	};

	useEffect(() => {
		// define a custom handler function
		// for the contextmenu event
		const handleContextMenu = (e) => {
			// prevent the right-click menu from appearing
			e.preventDefault();
		};

		// attach the event listener to
		// the document object
		document.addEventListener("contextmenu", handleContextMenu);

		// clean up the event listener when
		// the component unmounts
		return () => {
			document.removeEventListener("contextmenu", handleContextMenu);
		};
	}, []);


	const _prop = {
		onSceneChange: handleSceneChange,
		isAutoplay: isAutoplay
	}

	switch (currentScene) {
		case "intro":
			return <Intro {..._prop} />;

		case "scene1":
			return <Scene1.Intro {..._prop} />;
		case "scene1_choice1":
			return <Scene1.Choice1 {..._prop} />;
		case "scene1_choice2":
			return <Scene1.Choice2 {..._prop} />;
		case "scene1_choice3":
			return <Scene1.Choice3 {..._prop} />;

		case "scene2":
			return <Scene2.Intro {..._prop} />;
		case "scene2_choice1":
			return <Scene2.Choice1 {..._prop} />;
		case "scene2_choice2":
			return <Scene2.Choice2 {..._prop} />;
		case "scene2_choice3":
			return <Scene2.Choice3 {..._prop} />;

		case "scene3":
			return <Scene3.Intro {..._prop} />;
		case "scene3_choice1":
			return <Scene3.Choice1 {..._prop} />;
		case "scene3_choice2":
			return <Scene3.Choice2 {..._prop} />;
		case "scene3_choice3":
			return <Scene3.Choice3 {..._prop} />;

		case "outro":
			return <Outro {..._prop} />;

		default:
			return <Intro {..._prop} />;
	}
}

export default InteractiveVideo;
