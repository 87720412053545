import "./Scene2.scss";
import { Player, Video } from "liqvid";

import { DisablePauseClickCanvas } from "../../liqvid/components/Interactivity";
import { RememberVolume } from "../../liqvid/components/Recipes";

import {
	script_scene2_intro,
	playback_scene2_intro,
	script_scene2_choice1,
	playback_scene2_choice1,
	script_scene2_choice2,
	playback_scene2_choice2,
	script_scene2_choice3,
	playback_scene2_choice3,
} from "./markers";
import { customControls } from "../CustomControls/CustomControls";
import {
	SectionChoices,
	SectionTitle,
	SectionResult,
	animateBlur,
	Loading,
} from "../Sections/Sections";

import { useRef } from "react";

function Scene2ChoicesList({ script, playback, onSceneChange = () => {} }) {
	const handleChangeScene = (scene_name) => {
		return (event) => {
			if (playback && window.savedCurrentScene === scene_name) {
				playback.seek(0)
			} else {
				onSceneChange(scene_name);
			}
		};
	};

	const shuffleArray = (inputArray) => {
		for (var i = inputArray.length - 1; i > 0; i--) {
			var j = Math.floor(Math.random() * (i + 1)); //random index
			[inputArray[i], inputArray[j]] = [inputArray[j], inputArray[i]]; // swap
		}
	};

	const params = {
		title: "Et toi, que ferais-tu ?",
		choice_items: [
			{
				text: "Je mets le chevalet « Attention sol glissant »",
				onClick: handleChangeScene("scene2_choice1"),
			},
			{
				text: "Je range bien mon tuyau",
				onClick: handleChangeScene("scene2_choice2"),
			},
			{
				text: "Je dis à mon collègue de faire attention",
				onClick: handleChangeScene("scene2_choice3"),
			},
		],
	};

	shuffleArray(params.choice_items);

	return (
		<SectionChoices script={script} playback={playback} params={params} />
	);
}

function Intro({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene2_intro;
	const playback = playback_scene2_intro;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				ref={animateBlur(script, playback)}
				className="video_wrp"
			>
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene2/scene_2_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene2/scene_2.webm"
						type="video/webm; codecs=vp9"
					/>
					<source src="/videos/scene2/scene_2.mp4" type="video/mp4" />
				</Video>
			</section>
			<Scene2ChoicesList
				script={script}
				playback={playback}
				onSceneChange={onSceneChange}
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

function Choice1({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene2_choice1;
	const playback = playback_scene2_choice1;
	const videoRef = useRef(null);

	script.on("markerupdate", () => {
		var marker_name = script.markerName;
		if (marker_name === "video/redirection") {
			onSceneChange("scene3");
		}
	});

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section data-during="video/" className="video_wrp">
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene2/scene_2_1_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene2/scene_2_1.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/scene2/scene_2_1.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			<SectionTitle
				script={script}
				text="Je mets le chevalet « Attention sol glissant »"
			/>
			<SectionResult
				script={script}
				playback={playback}
				isGoodAnswer={true}
				text="Bravo ! Le chevalet permet de signaler un danger même lorsque je suis parti"
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

function Choice2({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene2_choice2;
	const playback = playback_scene2_choice2;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				ref={animateBlur(script, playback)}
				className="video_wrp"
			>
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene2/scene_2_2_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene2/scene_2_2.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/scene2/scene_2_2.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			<SectionTitle script={script} text="Je range bien mon tuyau" />
			<SectionResult
				script={script}
				playback={playback}
				text="Le rangement fait en effet partie des trois réflexes sécurité, mais ce n’est pas suffisant. Que peux-tu faire de plus ?"
			/>
			<Scene2ChoicesList
				script={script}
				playback={playback}
				onSceneChange={onSceneChange}
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

function Choice3({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene2_choice3;
	const playback = playback_scene2_choice3;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				ref={animateBlur(script, playback)}
				className="video_wrp"
			>
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene2/scene_2_3_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene2/scene_2_3.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/scene2/scene_2_3.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			<SectionTitle
				script={script}
				text="Je dis à mon collègue de faire attention"
			/>
			<SectionResult
				script={script}
				playback={playback}
				text="C’est toujours bien de prendre soin de ses collègues ! Mais si tu n’es plus là, qui va le faire à ta place ?"
			/>
			<Scene2ChoicesList
				script={script}
				playback={playback}
				onSceneChange={onSceneChange}
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

export const Scene2 = {
	Intro,
	Choice1,
	Choice2,
	Choice3,
};
