import React from "react";
import { usePlayer, useTime } from "liqvid";
import { between } from "@liqvid/utils/misc";

/**
 * Pausing the video 
 * usage : <PauseAt time="intro/pause"/> 
 */
export function PauseAt({
    marker_name,
    interval = 300
}) {
    const { playback, script } = usePlayer();
    const time = React.useMemo(() => script.parseStart(marker_name), [marker_name, script]);

    const prev = React.useRef(playback.currentTime);

    useTime(t => {
        if (between(time - interval, prev.current, time) && between(time, t, time + interval)) {
            playback.pause();
        }
        prev.current = t;
    }, []);

    return null;
}

/**
 * Remember the volume 
 * usage : <RememberVolume playback={playback}/> 
 */
export function RememberVolume({
    playback
}) {
    if (!playback) {
        return null
    }

    const storage = window.localStorage;

    // restore volume settings
    playback.volume = parseFloat(storage.getItem("liqvid volume") || "1");
    playback.muted = "true" === (storage.getItem("liqvid muted") || "false");

    // save volume settings
    playback.hub.on("volumechange", () => {
        storage.setItem("liqvid muted", playback.muted.toString());
        storage.setItem("liqvid volume", playback.volume.toString());
    });

    return null;
}