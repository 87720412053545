import { Script } from "liqvid";

export const markers_scene3_intro = [
	//[name, startTime, endTime], //(syntaxe)
    ["video/", "0:0", "0:11"],
    ["video/title", "0:11", "0:21"],
    ["video/choices", "0:21", "0:31"],
];
export const script_scene3_intro = new Script(markers_scene3_intro);
export const playback_scene3_intro = script_scene3_intro.playback;


export const markers_scene3_choice1 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:10"],
    ["video/result", "0:10", "0:18"],
    ["video/redirection", "0:18", "0:19"], // good answer
];
export const script_scene3_choice1 = new Script(markers_scene3_choice1);
export const playback_scene3_choice1 = script_scene3_choice1.playback;


export const markers_scene3_choice2 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:5"],
    ["video/result", "0:5", "0:13"],
    ["video/choices", "0:13", "0:23"],
];
export const script_scene3_choice2 = new Script(markers_scene3_choice2);
export const playback_scene3_choice2 = script_scene3_choice2.playback;


export const markers_scene3_choice3 = [
    //[name, startTime, endTime], //(syntaxe)
    ["video/title", "0:0", "0:14"],
    ["video/result", "0:14", "0:22"],
    ["video/choices", "0:22", "0:32"],
];
export const script_scene3_choice3 = new Script(markers_scene3_choice3);
export const playback_scene3_choice3 = script_scene3_choice3.playback;