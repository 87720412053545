import "./Scene3.scss";
import { Player, Video } from "liqvid";

import { DisablePauseClickCanvas } from "../../liqvid/components/Interactivity";
import { RememberVolume } from "../../liqvid/components/Recipes";

import {
	script_scene3_intro,
	playback_scene3_intro,
	script_scene3_choice1,
	playback_scene3_choice1,
	script_scene3_choice2,
	playback_scene3_choice2,
	script_scene3_choice3,
	playback_scene3_choice3,
} from "./markers";
import { customControls } from "../CustomControls/CustomControls";
import {
	SectionChoices,
	SectionTitle,
	SectionResult,
	animateBlur,
	Loading,
} from "../Sections/Sections";

import { useRef } from "react";

function Scene3ChoicesList({ script, playback, onSceneChange = () => {} }) {
	const handleChangeScene = (scene_name) => {
		return (event) => {
			if (playback && window.savedCurrentScene === scene_name) {
				playback.seek(0)
			} else {
				onSceneChange(scene_name);
			}
		};
	};

	const shuffleArray = (inputArray) => {
		for (var i = inputArray.length - 1; i > 0; i--) {
			var j = Math.floor(Math.random() * (i + 1)); //random index
			[inputArray[i], inputArray[j]] = [inputArray[j], inputArray[i]]; // swap
		}
	};

	const params = {
		title: "Et toi, que ferais-tu ?",
		choice_items: [
			{
				text: "J’utilise l’échelle pour transporter le plateau",
				onClick: handleChangeScene("scene3_choice1"),
			},
			{
				text: "Je suis efficace et je porte les restes directement avec le plateau",
				onClick: handleChangeScene("scene3_choice2"),
			},
			{
				text: "J’ajuste mon plateau pour faire attention",
				onClick: handleChangeScene("scene3_choice3"),
			},
		],
	};

	shuffleArray(params.choice_items);

	return (
		<SectionChoices script={script} playback={playback} params={params} />
	);
}

function Intro({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene3_intro;
	const playback = playback_scene3_intro;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				ref={animateBlur(script, playback)}
				className="video_wrp"
			>
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene3/scene_3_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene3/scene_3.webm"
						type="video/webm; codecs=vp9"
					/>
					<source src="/videos/scene3/scene_3.mp4" type="video/mp4" />
				</Video>
			</section>
			<Scene3ChoicesList
				script={script}
				playback={playback}
				onSceneChange={onSceneChange}
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

function Choice1({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene3_choice1;
	const playback = playback_scene3_choice1;
	const videoRef = useRef(null);

	script.on("markerupdate", () => {
		var marker_name = script.markerName;
		if (marker_name === "video/redirection") {
			onSceneChange("outro");
		}
	});

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section data-during="video/" className="video_wrp">
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene3/scene_3_1_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene3/scene_3_1.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/scene3/scene_3_1.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			<SectionTitle script={script} text="J’utilise l’échelle pour transporter le plateau" />
			<SectionResult
				script={script}
				playback={playback}
				isGoodAnswer={true}
				text="Bravo ! Utiliser le matériel adapté quelle que soit la charge, fait partie des réflexes sécurité."
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

function Choice2({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene3_choice2;
	const playback = playback_scene3_choice2;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				ref={animateBlur(script, playback)}
				className="video_wrp"
			>
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene3/scene_3_2_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene3/scene_3_2.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/scene3/scene_3_2.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			<SectionTitle script={script} text="Je suis efficace et je porte les restes directement avec le plateau" />
			<SectionResult
				script={script}
				playback={playback}
				text="Avec une charge portée à bras, je manque de visibilité et risque une chute ou un choc, qui sont les causes principales de nos accidents de travail"
			/>
			<Scene3ChoicesList
				script={script}
				playback={playback}
				onSceneChange={onSceneChange}
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

function Choice3({ onSceneChange = () => {}, isAutoplay = false }) {
	const controls = customControls(onSceneChange);
	const script = script_scene3_choice3;
	const playback = playback_scene3_choice3;
	const videoRef = useRef(null);

	return (
		<Player controls={controls} playback={playback} script={script}>
			<section
				data-during="video/"
				ref={animateBlur(script, playback)}
				className="video_wrp"
			>
				<Video className="vid_fluid" ref={videoRef} start={0}>
					<source
						src="/videos/scene3/scene_3_3_h265.mp4"
						type="video/mp4; codecs=hevc"
					/>
					<source
						src="/videos/scene3/scene_3_3.webm"
						type="video/webm; codecs=vp9"
					/>
					<source
						src="/videos/scene3/scene_3_3.mp4"
						type="video/mp4"
					/>
				</Video>
			</section>
			<SectionTitle
				script={script}
				text="J’ajuste mon plateau pour faire attention"
			/>
			<SectionResult
				script={script}
				playback={playback}
				text="C’est bien de faire attention, mais ce n’est pas toujours suffisant. Que peux-tu faire de plus ?"
			/>
			<Scene3ChoicesList
				script={script}
				playback={playback}
				onSceneChange={onSceneChange}
			/>
			{true && (
				<Loading
					autoPlay={isAutoplay}
					videoRef={videoRef}
					playback={playback}
				/>
			)}
			{true && <DisablePauseClickCanvas />}
			{true && <RememberVolume playback={playback} />}
		</Player>
	);
}

export const Scene3 = {
	Intro,
	Choice1,
	Choice2,
	Choice3,
};
