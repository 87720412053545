import { useState } from "react";
import "./PlayList.scss";
import { usePlayback } from "liqvid";

export default function PlayList({ onSceneChange = () => {} }) {
    //const storage = window.localStorage;
    const [isShowList, setIsShow] = useState(false)

    //const savedCurrentScene = (storage.getItem("saved_current_scene") || "intro")
    const savedCurrentScene = window.savedCurrentScene || "intro"
    var current_playback = usePlayback()

    const handleToggle = () => {
        setIsShow(!isShowList)
    }

    const handleChangeScene = (scene_name, scenes_k) => {
        return (event) => {
            if (!isDisabled(scenes_k)) {
                setIsShow(!isShowList)
                if (current_playback && window.savedCurrentScene === scene_name) {
                    current_playback.seek(0)
                } else {
                    onSceneChange(scene_name);
                }
            }
        };
    };

    const isShowListing = isShowList ? "show_list" : ""

    const intros = [
        "intro",
    ]

    const scenes1 = [
        "scene1",
        "scene1_choice1",
        "scene1_choice2",
        "scene1_choice3",
    ]

    const scenes2 = [
        "scene2",
        "scene2_choice1",
        "scene2_choice2",
        "scene2_choice3",
    ]

    const scenes3 = [
        "scene3",
        "scene3_choice1",
        "scene3_choice2",
        "scene3_choice3",
    ]

    const outros = [
        "outro",
    ]

    const obj_scenes = {
        intros,
        scenes1,
        scenes2,
        scenes3,
        outros,
    }

    const isActive = (scenes_k) => {
        return (obj_scenes[scenes_k] && obj_scenes[scenes_k].indexOf(savedCurrentScene) !== -1) ? true : false
    }

    const isDisabled = (scenes_k) => {
        var indexOfScenesKinObjScenes = Object.keys(obj_scenes).indexOf(scenes_k)
        var indexOfActiveSceneInObjScenes = 0

        Object.keys(obj_scenes).map((_scenes_k, i_scenes_k) => {
            //console.log("xx", obj_scenes[_scenes_k])
            if (obj_scenes[_scenes_k].indexOf(savedCurrentScene) !== -1) {
                indexOfActiveSceneInObjScenes = i_scenes_k
            }
            return null
        })

        return (indexOfActiveSceneInObjScenes >= indexOfScenesKinObjScenes) ? false : true
    }

    const getClassIsActive = (scenes_k) => {
        return isActive(scenes_k) ? "active" : ""
    }

    const getClassIsDisable = (scenes_k) => {
        return isDisabled(scenes_k) ? "disabled" : ""
    }

    /*console.log(
        savedCurrentScene,
    {
        "intro": isDisabled("intros"),
        "scene1": isDisabled("scenes1"),
        "scene2": isDisabled("scenes2"),
        "scene3": isDisabled("scenes3"),
        "outro": isDisabled("outros"),
    })*/

    return (
        <div className={`playlist_wrp ${isShowListing}`}>
            <button
                className="toggler"
                onClick={handleToggle}
            >
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div className={`playlist_list_wrp`}>
                <ul className="playlist_list">
                   <li className={`playlist_list_item ${getClassIsActive("intros")} ${getClassIsDisable("intros")}`} onClick={handleChangeScene("intro", "intros")}>
                       <div className="content">Intro</div>
                   </li> 
                   <li className={`playlist_list_item ${getClassIsActive("scenes1")} ${getClassIsDisable("scenes1")}`} onClick={handleChangeScene("scene1", "scenes1")}>
                       <div className="content">Scene#1 - L'ESH</div>
                   </li> 
                   <li className={`playlist_list_item ${getClassIsActive("scenes2")} ${getClassIsDisable("scenes2")}`} onClick={handleChangeScene("scene2", "scenes2")}>
                       <div className="content">Scene#2 - Le plongeur</div>
                   </li> 
                   <li className={`playlist_list_item ${getClassIsActive("scenes3")} ${getClassIsDisable("scenes3")}`} onClick={handleChangeScene("scene3", "scenes3")}>
                       <div className="content">Scene#3 - L'ESH</div>
                   </li> 
                   <li className={`playlist_list_item ${getClassIsActive("outros")} ${getClassIsDisable("outros")}`} onClick={handleChangeScene("outro", "outros")}>
                       <div className="content">Conclusion</div>
                   </li> 
                </ul>
            </div>
        </div>
    )
}